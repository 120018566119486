import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-dropdown-field',
  standalone: true,
  imports: [CommonModule, DropdownModule, ReactiveFormsModule],
  template: `
    <!-- <div class="form_div"> -->
      <label [for]="inputId">{{label}}</label>
      <div class="mt-2 rounded-md border">
        <p-dropdown 
          [options]="options" 
          [formControl]="formControl"
          [placeholder]="placeHolder"
          [optionLabel]="optionLabel"
          [inputId]="inputId"
          [style]="{'width': '100%'}"
          [appendTo]="'body'"
          [scrollHeight]="'200px'">
        </p-dropdown>
      </div>
      @if(formControl.invalid && (formControl.touched || formSubmitted)){
        <div class="flex flex-col items-start mt-2 text-xs italic text-red-400">
          <span>{{errorMessage || label + ' is required'}}</span>
        </div>
      }
    <!-- </div> -->
  `,
  styles: [`
    .form_div {
        @apply flex flex-col w-full justify-start space-y-2 mt-4; 
    }
  `]
})
export class DropdownFieldComponent {
  @Input() label: string = '';
  @Input() inputId: string = '';
  @Input() placeHolder: string = 'Select an option';
  @Input() options: any[] = [];
  @Input() optionLabel: string = 'name';
  @Input() formControl!: FormControl;
  @Input() formSubmitted: boolean = false;
  @Input() errorMessage?: string;
} 