import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from '@components/table/table.component';
import { OrderService, OutletService } from '@data-access/services';
import { Order, OrderReport, ResponseDTO } from '@models/index';
import { format } from 'date-fns';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { first } from 'rxjs';
import { OutletComponent } from '../outlet/outlet.component';
import { PrimeTableColumn } from '@models/prime-interface/prime-interface';
import { AppDialogComponent } from "../../shared/app-dialog/app-dialog.component";
import { PanelModule } from 'primeng/panel';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-outlet-report',
  standalone: true,
  templateUrl: './outlet-report.component.html',
  styleUrl: './outlet-report.component.css',
  imports: [OutletComponent, CardModule, PanelModule, FormsModule, ReactiveFormsModule, CalendarModule, DropdownModule, TableComponent, AppDialogComponent]
})
export class OutletReportComponent {


  constructor(private orderService: OrderService, private outletService: OutletService, private toastr: ToastrService) {
    this.outletService.outlet$.subscribe((result) => {
      this.outlet = result;
    });
  }
  total: number = 0;
  outlet: any;
  startDate = '';
  endDate = '';
  orders: Order[] = [];
  reports: object[] = [{ name: 'Order' }];
  currency: string = 'NGN';
  showConfigureDialog: boolean = false;
  maxDate: Date = new Date();
  tableCols: PrimeTableColumn[] = [
    { field: 'customerName', header: 'Customer' },
    { field: 'amount', header: 'Amount' },
    { field: 'status', header: 'Status' },
    { field: 'paid', header: 'Paid' },
    { field: 'deliveryMode', header: 'Delivery' },
    { field: 'formattedDate', header: 'Date' },];


  showDialog(index: number) {
    console.log("Button Pushed", index);
  }

    startDateChanged(event: Date) {
      // this.fetchOrders(this.outlet.id, , event.toISOString());
      this.startDate = event.toISOString();
      console.log(this.startDate);
    }

    endDateChanged(event: Date) {
      this.endDate = event.toISOString();
      console.log(this.endDate);
    }

    queryOrders() {
      console.log(this.startDate, this.endDate);
      if(this.startDate && this.endDate){
        this.fetchOrders(this.outlet.id);
        this.toggleConfigureDialog();
      }
      else{
        this.toastr.error('Please select a date range');
      }
    }

  toggleConfigureDialog() {
    this.showConfigureDialog = !this.showConfigureDialog;
  }

  fetchOrders(id: number) {
    this.orderService.getOutletOrdersWithDate(id, this.startDate, this.endDate)?.pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          const data = result.data as OrderReport;
          this.total = data.total;
          if (data?.orders?.length > 0) {
            this.toastr.success('Orders fetched successfully');
            this.orders = data.orders.map((o: Order) => ({ ...o, formattedDate: format(o.orderDate as Date, 'dd MMM yyyy'), customerName: o.customer.name }));
          }
          else{
            this.toastr.info('No orders found for the selected date range');
          }
        }
      },
      error: (e) => {
        console.log(e);
      }
    });
  }
}
