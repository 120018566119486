<app-auth-layout>

  <div class="flex flex-col items-center w-full">
    <form [formGroup]="loginForm" (submit)="login()">
      <div class="flex flex-col md:w-[100%] items-center">
        <h1 class="mb-2 text-2xl font-bold md:text-3xl md:mb-4">Log in to your Account</h1>
        <span class="text-sm text-center text-gray-500 md:text-base">Sign in to your account and make orders and
          payments seamlessly</span>
      </div>
      <app-text-input-field [formSubmitted]="loginFormSubmitted" ngDefaultControl label="Email" inputId="registerEmail"
        placeHolder="Enter Your Email" [formControl]="loginForm.controls.email"
        inputType="email"></app-text-input-field>

      <app-password-field [submitted]="loginFormSubmitted" ngDefaultControl inputId="password3"
        [passwordControl]="loginForm.controls.password">
      </app-password-field>

      <!-- <app-password-field [submitted]="registerFormSubmitted" ngDefaultControl inputId="password2"
        [formControl]="registerForm.controls.password" (passwordFocus)="showPasswordRequirements = true"
        (passwordBlur)="showPasswordRequirements = false"></app-password-field> -->

      <div class="flex justify-between mt-4 w-full">
        <div class="space-x-3 text-sm md:text-base">
          <!-- <input type="checkbox" name="" id="" />
          <label for="">Remember me</label> -->
        </div>
        <div class="space-x-1">
          <a routerLink="/forgot-password" routerLinkActive="active"
            class="hover:underline text-sm md:text:base font-[500] hover:cursor-pointer">Forgot password ?</a>
        </div>
      </div>
      <div class="form_div">
        <button
          class="create_button flex justify-center space-x-4 bg-vendblocpurple text-white hover:bg-[#FAFAFA] hover:text-vendblocpurple hover:shadow-lg duration-300"
          type="submit">
          <span>Login</span>
        </button>
      </div>
    </form>
    <!-- <div class="mt-3">Or</div> -->
    <div class="items-center form_div">
      <div id="googleSignInDiv"></div>
    </div>
    <div class="flex flex-col items-center mt-6 w-full">
      <div class="flex flex-row justify-center space-x-1">
        <span class="text-gray-500 font-[400]"> Don't have an account ? </span>
        <a routerLink="/register" routerLinkActive="active"
          class="hover:underline font-[500] hover:cursor-pointer">Create
          an account</a>
      </div>
      <div class="flex flex-row justify-center mt-3 space-x-1">
        <span class="text-gray-500 font-[400]">
          Request Email Verification ?
        </span>
        <a routerLink="/request-verification" routerLinkActive="active"
          class="hover:underline font-[500] hover:cursor-pointer">Request </a>
      </div>
    </div>
  </div>
</app-auth-layout>