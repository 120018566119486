<app-outlet>

    <p-card header="Report">
        <app-dialog (confirm)="queryOrders()" [(visible)]="showConfigureDialog" [style]="{ width: '40vw' }" header="Configure Report">
            <form>
                <div class="flex flex-col mb-2 space-x-2 w-full md:flex-row">
                    <div class="flex flex-col space-y-2 w-full">
                        <label for="">Report Type</label>
                        <p-dropdown class="w-auto rounded-lg border custom-dropdown" [options]="reports"
                            optionLabel="name" showClear="true" placeholder="Select a Type"></p-dropdown>
                    </div>
                </div>
                <div class="flex flex-col gap-2 md:flex-row">
                    <div class="form_div">
                        <label for="">Start Date</label>
                            <p-calendar [maxDate]="maxDate" [style]="{'width':'100%'}" class="px-2 py-3 rounded-lg border custom-calendar" (onSelect)="startDateChanged($event)"
                            [(ngModel)]="startDate" [showIcon]="true" inputId="templatedisplay" appendTo="body">
                        </p-calendar>
                    </div>
                    <div class="form_div">
                        <label for="">End Date</label>
                        <p-calendar [maxDate]="maxDate" [style]="{'width':'100%'}" [panelStyle]="{'width':'280px'}" class="px-2 py-3 rounded-lg border custom-calendar" (onSelect)="endDateChanged($event)"
                            [(ngModel)]="endDate" [showIcon]="true" inputId="templatedisplay" appendTo="body">
                        </p-calendar>
                    </div>
                </div>
            </form>
        </app-dialog>

        <div class="mb-4">
            <p-panel>
                <div class="flex flex-col md:flex-row">
                    <div class="flex justify-between space-x-2 w-full">
                        <div class="w-auto">
                            <button (click)="toggleConfigureDialog()"
                                class="border px-[10px] text-white  rounded-md text-center text-[15px] hover:bg-vendblocyellow text-[400] py-2 flex justify-center space-x-2 bg-vendblocpurple hover:text-[#0F172A] hover:shadow-lg duration-300"
                                type="submit">
                                <span>Configure Report</span>
                            </button>
                        </div>
                        <div>
                            <div class="w-1/3 md:w-auto">
                                <div class="flex flex-col justify-end space-y-4">
                                    <label class="" for="">Total Cost</label>
                                    <span class="justify-center font-bold text-center">{{total }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-panel>
        </div>

        <app-table [cols]="tableCols" [enableShow]="true" (showButtonPressed)="showDialog($event)" [tableData]="orders"
            tableNamePlural="Orders" tableNameSingular="Order"></app-table>
    </p-card>

</app-outlet>