import { Component } from '@angular/core';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { CreateOutlet, Currency, Outlet, ResponseDTO, Store, User } from '@models/index';
import { AuthService, CurrencyService, OutletService } from '@data-access/services';
import { TableComponent } from "../table/table.component";
import { PrimeTableColumn } from '@models/prime-interface/prime-interface';
import { AdminLayoutComponent } from "../layouts/admin-layout/admin-layout.component";
import { BreadcrumbComponent } from "../shared/breadcrumb/breadcrumb.component";
import { MenuItem } from 'primeng/api';
import { FileUploadEvent, FileUploadModule } from 'primeng/fileupload';

@Component({
    selector: 'app-my-outlets',
    standalone: true,
    templateUrl: './my-outlets.component.html',
    styleUrl: './my-outlets.component.css',
    imports: [TableModule, ReactiveFormsModule, DropdownModule, ButtonModule, DialogModule, TableComponent, AdminLayoutComponent, BreadcrumbComponent, FileUploadModule]
})
export class MyOutletsComponent {


  breadcrumbItems: MenuItem[] = [
    { label: 'My Outlets' },
  ];

  homeItem: MenuItem = {
    icon: 'pi pi-shop',
    routerLink: '/'
  };
  loading: boolean = false;
  visible: boolean = false;
  currencies: Currency[] = [];
  user: User | null = null;
  outlets: Outlet[] = [];
  enableAdd:boolean = false;
  stores: Store[] = [];
selectedFile: any;
  showDialog() {
    this.visible = true;
  }

  tableCols: PrimeTableColumn[] = [
    { field: 'name', header: 'Name' },
    { field: 'store', header: 'Store' },
    { field: 'id', header: '' }
  ];

  constructor(private currencyService: CurrencyService,
    private outletService: OutletService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router) {
  }

  onUpload($event: FileUploadEvent) {
    this.selectedFile = $event.files[0];
    }

  ngOnInit(): void {
    this.authService.user$.pipe(first()).subscribe((response) => {
      this.user = response;
      if(this.user?.role === "uncleshenor"){
        this.enableAdd = true;
      }
    });

    this.currencyService.getCurrencies().pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.currencies = result.data as Currency[];
        }
      },
      error: (e) => {
        console.log(e);
      }
    });


    this.outletService.getOutletsByUser(this.user?.id as string).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.outlets = result.data as Outlet[];
          this.stores = this.getUniqueStores(this.outlets);
          
          console.log(this.outlets);
        }
      },
      error: (e) => {
        this.toastr.error("Oops, pls try again, something went wrong");
        console.log(e);
      }
    });
  }

  createOutletForm = new FormGroup({
    name: new FormControl('',[Validators.required, Validators.minLength(3)]),
    currency: new FormControl(),
    storeId: new FormControl(),
    opening: new FormControl(),
    closing: new FormControl()
  });

  navigateToOutlet(id: number) {
    const outletRoute = 'outlet/' + id;
    this.router.navigate([outletRoute]);
  }

  createOutlet() {
    if(!this.createOutletForm.valid)return;
    const formValue = this.createOutletForm.value;
    const data: CreateOutlet = {
      name: formValue.name as string,
      currencyId: formValue.currency.id,
      storeId: formValue.storeId?.id,
      opening: formValue.opening as string,
      closing: formValue.closing as string
    };
    this.outletService.createOutlet(data, this.selectedFile).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.toastr.success(response.message);
          const result = response.data as Outlet;
          this.outlets.push(result);
          this.visible = false;
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: (err) => {
        console.log(err);
        this.toastr.error("Something went wrong");
      },
    })
  }

  getUniqueStores(outlets: any[]): { id: number, name: string }[] {
    const uniqueStores = new Map();
    
    outlets.forEach(outlet => {
      if (!uniqueStores.has(outlet.storeId)) {
        uniqueStores.set(outlet.storeId, {
          id: outlet.storeId,
          name: outlet.store
        });
      }
    });

    return Array.from(uniqueStores.values());
  }
}
