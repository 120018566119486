<app-outlet>
    <app-table [cols]="tableCols" [enableAdd]="true" (addButtonPressed)="showDialog()"
        (removeButtonPressed)="deleteProduct($event)" [tableData]="products" [enableEdit]="true"
        (editButtonPressed)="showEditDialog($event)" tableNamePlural="Products" tableNameSingular="Product"></app-table>

    <app-dialog [(visible)]="productDialog" header="Add Product" width="50%" [showFooter]=true (confirm)="saveProduct()"
        (cancel)="showDialog()">
        <form [formGroup]="createProductForm" (submit)="saveProduct()">

            <div class="flex flex-col gap-2 md:flex-row">
                <div class="basis-1/2">
                    <app-text-input-field [formSubmitted]="formSubmitted"
                        [formControl]="createProductForm.controls.name" ngDefaultControl inputType="text" label="Name"
                        inputId="name" placeHolder="Enter product name">
                    </app-text-input-field>
                </div>
                <div class="basis-1/2 form_div">
                    <app-dropdown-field
                        [formSubmitted]="formSubmitted"
                        [formControl]="createProductForm.controls.productCategory"
                        [options]="productCategories"
                        label="Category"
                        inputId="categoryId"
                        placeHolder="Select a Category">
                    </app-dropdown-field>
                </div>
            </div>

            <div class="flex flex-col gap-2 w-full md:flex-row">
                <div class="form_div">
                    <app-dropdown-field
                        [formSubmitted]="formSubmitted"
                    [formControl]="createProductForm.controls.productType"
                    [options]="productTypes"
                    label="Type"
                    inputId="productType"
                        placeHolder="Select a Type">
                    </app-dropdown-field>
                </div>
                <div class="form_div">
                    <app-dropdown-field
                        [formSubmitted]="formSubmitted"
                        [formControl]="createProductForm.controls.unit"
                        [options]="units"
                        label="Unit of Measurement"
                        inputId="unit"
                        placeHolder="Select a Unit">
                    </app-dropdown-field>
                </div>
            </div>
            <div class="form_div">
                <label for="">Image</label>
                <p-fileUpload [auto]="true" mode="basic" [chooseLabel]="selectedFile?.name || 'Choose product image'"
                    chooseIcon="pi pi-upload" name="demo[]"
                    [style]="{'border': '1px solid #ccc', 'border-radius': '5px', 'padding': '10px', 'width': '100%'}"
                    url="https://www.primefaces.org/cdn/api/upload.php" accept="image/*" maxFileSize="100000"
                    (onUpload)="onUpload($event)" />
            </div>
            <div class="form_div">
                <app-textarea-field [formSubmitted]="formSubmitted"
                    [formControl]="createProductForm.controls.description" ngDefaultControl label="Description"
                    inputId="description" placeHolder="Enter product description" [maxChars]="200"
                    [showCharCount]="true"></app-textarea-field>
            </div>
        </form>
    </app-dialog>

    <app-dialog [(visible)]="editDialog" header="Edit {{selectedProduct?.name}}" width="50%" [showFooter]=true
        (confirm)="updateProduct()" (cancel)="closeEditDialog()">
        <form [formGroup]="editProductForm">
            <div class="flex flex-row justify-center">
                <!-- <div class="p-3 m-2 border-2 surface-border border-round">
                    <div class="flex flex-row justify-center mb-3">
                        <div class="relative mx-auto">
                            <p-avatar image="{{selectedProduct?.image}}"
                            styleClass="mr-2" size="large" shape="circle" />
                        </div>
                    </div>
                    <div class="flex justify-content-between align-items-center">
                        <span>
                            <p-button icon="pi pi-heart" severity="secondary" [outlined]="true" />
                            <p-button icon="pi pi-upload" styleClass="ml-2" />
                        </span>
                    </div>
                </div> -->
            </div>
            <div class="flex flex-col gap-2 md:flex-row">
                <div class="basis-1/2">
                    <app-text-input-field [formSubmitted]="formSubmitted" [formControl]="editProductForm.controls.name"
                        ngDefaultControl inputType="text" label="Name" inputId="edit_name"></app-text-input-field>
                </div>

                <div class="basis-1/2">
                    <div class="form_div">
                        <app-dropdown-field
                            [formSubmitted]="formSubmitted"
                        [formControl]="editProductForm.controls.productCategory"
                        [options]="productCategories"
                        label="Category"
                        inputId="categoryId"
                        placeHolder="Select a Category">
                        </app-dropdown-field>
                    </div>
                </div>
            </div>
            
            <div class="flex flex-col gap-2 md:flex-row">
                <div class="form_div">
                    <app-dropdown-field
                        [formSubmitted]="formSubmitted"
                    [formControl]="editProductForm.controls.productType"
                    [options]="productTypes"
                    label="Type"
                    inputId="productType"
                        placeHolder="Select a Type">
                    </app-dropdown-field>
                </div>
                <div class="form_div">
                    <app-dropdown-field
                    [formSubmitted]="formSubmitted"
                    [formControl]="editProductForm.controls.unit"
                    [options]="units"
                    label="Unit of Measurement"
                    inputId="unit"
                    placeHolder="Select a Unit">
                    </app-dropdown-field>
                </div>
            </div>
            <div class="form_div">
                <label for="">Image</label>
                <p-fileUpload [auto]="true" mode="basic" [chooseLabel]="selectedFile?.name || 'Choose product image'"
                    chooseIcon="pi pi-upload" name="demoTest[]"
                    [style]="{'border': '1px solid #ccc', 'border-radius': '5px', 'padding': '10px', 'width': '100%'}"
                    url="https://www.primefaces.org/cdn/api/upload.php" accept="image/*" maxFileSize="100000"
                    (onUpload)="onUpload($event)" />
            </div>
            <div class="form_div">
                <app-textarea-field [formSubmitted]="formSubmitted" [formControl]="editProductForm.controls.description"
                    ngDefaultControl label="Description" inputId="edit_description"
                    placeHolder="Enter product description" [maxChars]="200"
                    [showCharCount]="true"></app-textarea-field>
            </div>
        </form>
    </app-dialog>
</app-outlet>