import { Component, Input } from '@angular/core';
import { TableComponent } from "../../table/table.component";
import { Order } from '@models/index';
import { AppDialogComponent } from "../app-dialog/app-dialog.component";
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-order-invoice',
  standalone: true,
  imports: [TableComponent, AppDialogComponent, TableModule],
  templateUrl: './order-invoice.component.html',
  styleUrl: './order-invoice.component.css'
})
export class OrderInvoiceComponent {
  statusChanged(arg0: string) {
    throw new Error('Method not implemented.');
  }
  tableCols: any[] = [
    { field: 'name', header: 'Customer' },
    { field: 'price', header: 'Order Id' },
    { field: 'quantity', header: 'Status' },
    { field: 'total', header: 'Paid' }
  ];

  @Input() order!: Order;
  @Input() showDialog: boolean = false;
  @Input() orderStatus: any = [];

  onCancelAction() {
    console.log('Adesina')
    throw new Error('Method not implemented.');
  }
  onConfirmAction() {
    throw new Error('Method not implemented.');
  }

  createRange(range: number) {
    return new Array(range)
  }
}
