<app-outlet>
    <app-table [cols]="tableCols" [enableAdd]="true" [enableDelete]="true" (removeButtonPressed)="removeWorker($event)"
        (addButtonPressed)="showDialog()" [tableData]="workers" tableNamePlural="Workers" tableNameSingular="Worker"></app-table>

    <form [formGroup]="createWorkerForm" (submit)="createUser()">
        <p-dialog [(visible)]="addWorkerDialog" [style]="{ width: '450px' }" header="Add Worker" [modal]="true"
            styleClass="p-fluid">
            <ng-template pTemplate="content">
                <div class="form_div">
                    <label for="">Role</label>
                    <p-dropdown class="rounded-md border" [options]="roles" formControlName="role" placeholder="Select a Role"
                        optionLabel="name" inputId="float-label"></p-dropdown>
                </div>
                <div class="form_div">
                    <label for="">User's Email</label>
                    <input class="p-3 rounded-md border" placeholder="Email" formControlName="userEmail" type="text"
                        required />
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <div class="flex justify-end items-end">
                    <button class="px-6 py-2 text-white rounded-sm bg-vendblocblue" type="submit">Create</button>
                </div>
            </ng-template>

        </p-dialog>
    </form>

</app-outlet>