import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TableComponent } from '@components/table/table.component';
import { OutletService, ProductService } from '@data-access/services';
import { Outlet, Product, ProductCategory, ProductType, ResponseDTO, Unit } from '@models/index';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { Subject, first, takeUntil } from 'rxjs';
import { OutletComponent } from '../outlet/outlet.component';
import { PrimeTableColumn } from '@models/prime-interface/prime-interface';
import { AppDialogComponent } from "../../shared/app-dialog/app-dialog.component";
import { TextInputFieldComponent } from '../../shared/text-input-field/text-input-field.component';
import { TextareaFieldComponent } from '@components/shared/textarea-field/textarea-field.component';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { DropdownFieldComponent } from '@components/shared/dropdown-field/dropdown-field.component';

@Component({
  selector: 'app-outlet-products',
  standalone: true,
  templateUrl: './outlet-products.component.html',
  styleUrl: './outlet-products.component.css',
  imports: [OutletComponent, CardModule, CardModule, TableModule, ButtonModule, DialogModule,
    ToolbarModule, FileUploadModule, InputNumberModule, DropdownModule, InputTextModule, RippleModule,
    ReactiveFormsModule, ConfirmPopupModule, ToastModule, TableComponent, AppDialogComponent, TextInputFieldComponent, TextareaFieldComponent, AvatarModule, AvatarGroupModule, DropdownFieldComponent]
})
export class OutletProductsComponent implements OnDestroy {

  products: Product[] = [];
  productTypes: ProductType[] = [{ id: 0, name: "Perishable" }, { id: 1, name: "Non-Perishable" }];
  productType: number = 0;
  outlet: Outlet | undefined = undefined;
  units: Unit[] = [];
  productCategories: ProductCategory[] = [];
  productDialog: boolean = false;
  editDialog: boolean = false;
  selectedProduct: Product | undefined = undefined;
  tableCols: PrimeTableColumn[] = [
    { field: 'image', header: '.' },
    { field: 'name', header: 'Name' },
    { field: 'productCategory', header: 'Category' },
    { field: 'unit', header: 'Unit' },
    { field: 'productType', header: 'Type' }];
  private $destroy = new Subject<void>();
  formSubmitted: boolean = false;
  selectedFile: File | null = null;

  constructor(private outletService: OutletService, private confirmationService: ConfirmationService, private messageService: MessageService, private productService: ProductService, private toastr: ToastrService) {
    this.outletService.outlet$.pipe(takeUntil(this.$destroy)).subscribe((result) => {
      this.outlet = result as Outlet;
    });
    this.fetchProducts(this.outlet?.id ?? 0);
    this.fetchProductCategories(this.outlet?.id ?? 0);
    this.fetchUnits(this.outlet?.storeId ?? 0);
  }

  onUpload($event: FileUploadEvent) {
    if ($event.files && $event.files.length > 0) {
      this.selectedFile = $event.files[0];
    }
  }

  showDialog() {
    this.selectedFile = null;
    this.createProductForm.reset();
    this.productDialog = !this.productDialog;
    if (!this.productDialog) {
      this.createProductForm.reset();
      this.formSubmitted = false;
      Object.keys(this.createProductForm.controls).forEach(key => {
        const control = this.createProductForm.get(key);
        control?.markAsUntouched();
        control?.markAsPristine();
      });
    }
  }

  closeEditDialog() {
    this.selectedFile = null;
    this.editDialog = false;
    this.editProductForm.reset();
    this.formSubmitted = false;
    Object.keys(this.editProductForm.controls).forEach(key => {
      const control = this.editProductForm.get(key);
      control?.markAsUntouched();
      control?.markAsPristine();
    });
  }

  showEditDialog($event: number) {
    this.selectedFile = null;
    this.editDialog = !this.editDialog;
    if (!this.editDialog) {
      this.editProductForm.reset();
    }
    else {
      const product = this.products[$event];
      if (product) {
        this.selectedProduct = product;
        const selectedCategory = this.productCategories.find(cat => cat.id === product.productCategoryId) || null;
        const selectedUnit = this.units.find(unit => unit.id === product.unitId) || null;
        const selectedType = this.productTypes.find((types) => types?.name === product.productType) || null;

        this.editProductForm.patchValue({
          name: product.name,
          description: product.description,
          productCategory: selectedCategory,
          unit: selectedUnit,
          productType: selectedType
        });
      }
    }
  }

  createProductForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    description: new FormControl(''),
    productCategory: new FormControl<ProductCategory | null>(null, Validators.required),
    unit: new FormControl<Unit | null>(null, Validators.required),
    productType: new FormControl<ProductType | null>(null, Validators.required)
  });

  editProductForm = new FormGroup({
    name: new FormControl(),
    description: new FormControl(),
    productCategory: new FormControl(),
    unit: new FormControl(),
    productType: new FormControl()
  });

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  // validateForm(formData: Partial<{
  //   name: string | null;
  //   description: string | null;
  //   productCategory: ProductCategory | null;
  //   unit: Unit | null;
  //   productType: ProductType | null;
  // }>): boolean {
  //   if (!formData.name) {
  //     this.toastr.error("Please enter a valid name");
  //     return false;
  //   }
  //   if (!formData.productCategory) {
  //     this.toastr.error("Please select a category");
  //     return false  ;
  //   }
  //   if (!formData.unit) {
  //     this.toastr.error("Please select a unit");
  //     return false;
  //   }
  //   if (!formData.productType) {
  //     this.toastr.error("Please select a type");
  //     return false;
  //   }
  //   return true;
  // }


  saveProduct() {
    this.formSubmitted = true;
    const formData = this.createProductForm.value;
    console.log(formData);
    if(this.createProductForm.invalid){
      return;
    }
    const formData2 = new FormData();
    formData2.append('name', formData.name as string);
    formData2.append('description', formData.description as string);
    formData2.append('unitId', formData.unit?.id?.toString() ?? '0');
    formData2.append('productCategoryId', formData!.productCategory?.id?.toString() ?? '0');
    formData2.append('productTypeId', formData.productType?.id?.toString() ?? '0');
    console.log(formData2);
    if (this.selectedFile) {
      formData2.append('image', this.selectedFile!, this.selectedFile?.name);
    }
    formData2.append('storeId', this.outlet?.storeId?.toString() ?? '0');
    this.productService.createProduct(formData2).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.createProductForm.reset();
          this.toastr.success(response.message);
          const newProduct = response.data as Product;
          this.products.push(newProduct);
          this.showDialog();
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: (err) => {
        console.log(err);
        this.toastr.error("Something went wrong");
      },
    });
  }

  updateProduct() {
    this.formSubmitted = true;
    const formData = this.editProductForm.value;
    const productId = this.selectedProduct?.id ?? 0;
    if(this.editProductForm.invalid){
      return;
    }
    const formData2 = new FormData();
    formData2.append('id', productId.toString());
    formData2.append('name', formData.name);
    formData2.append('description', formData.description);
    formData2.append('unitId', formData.unit?.id?.toString() ?? '0');
    if (this.selectedFile) {
      formData2.append('image', this.selectedFile!, this.selectedFile?.name);
    }
    formData2.append('productCategoryId', formData.productCategory.id.toString());
    formData2.append('productTypeId', formData.productType.id.toString());
    this.productService.updateProduct(formData2).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.toastr.success(response.message);
          const productIndex = this.products.findIndex(x => x.id === productId);
          if (productIndex !== -1) {
            console.log(this.products[productIndex]);
            this.products[productIndex] = { ...response.data as Product };
            console.log(this.products[productIndex]);
          }
          this.closeEditDialog();
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: (err) => {
        console.log(err);
        this.toastr.error("Something went wrong");
      },
    });
  }

  deleteProductAPI(id: number) {
    this.productService.deleteProduct(id).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.products = this.products.filter(x => x.id !== id);
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: (err) => {
        console.log(err);
        this.toastr.error("Something went wrong");
      },
    });
  }

  deleteProduct(eventAndId: { event: Event, id: number; }) {
    const event: Event = eventAndId.event;
    const id: number = eventAndId.id;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Do you want to delete this record?',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-sm',
      accept: () => {
        this.deleteProductAPI(id);
        this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted', life: 3000 });
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
      }
    });
  }

  public fetchProducts(outlet: number) {
    if (outlet === 0) return;
    this.outletService.getOutletProducts(outlet).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.products = result.data as Product[];
        }
        else {
          console.log("something went wrong");
        }
      },
      error: (err) => {
        console.log("Something went wrong");
        console.log(err);
      }
    });
  }

  public fetchProductCategories(outlet: number) {
    if (outlet === 0) return;
    this.outletService.getOutletProductCategories(outlet).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.productCategories = result.data as ProductCategory[];
        }
        else {
          console.log("something went wrong");
        }
      },
      error: () => {
        console.log("Something went wrong");
      }
    });
  }

  public fetchUnits(store: number) {
    if (store === 0) return;
    this.outletService.getOutletUnits(store).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.units = result.data as Unit[];
        }
        else {
          console.log("else something went wrong");
        }
      },
      error: () => {
        console.log("error Something went wrong");
      }
    });
  }
}
