import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-textarea-field',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './textarea-field.component.html',
  styleUrl: './textarea-field.component.css'
})
export class TextareaFieldComponent {
  @Input() formControl: FormControl = new FormControl('');
  @Input() label: string = '';
  @Input() inputId: string = '';
  @Input() placeHolder: string = '';
  @Input() formSubmitted: boolean = false;
  @Input() inputType: string = 'text';
  @Input() maxChars: number = 200;
  @Input() showCharCount: boolean = false;

  get currentLength(): number {
    return this.formControl.value?.length || 0;
  }

  showError: boolean = false;
  @Input() disabled: boolean = false;
}
