<app-admin-layout>
    <app-breadcrumb [items]="breadcrumbItems" [home]="homeItem"></app-breadcrumb>
    <app-table [cols]="tableCols" [enableOpen]="true" (openButtonPressed)="navigateToOutlet($event)"
        [enableAdd]="enableAdd" (addButtonPressed)="showDialog()" [tableData]="outlets" tableNamePlural="Outlets"
        tableNameSingular="Outlet"></app-table>

    <p-dialog header="Add Outlet" [(visible)]="visible" position="top" [modal]="true" [style]="{ width: '50vw' }"
        [draggable]="false" [resizable]="false" styleClass="custom-dialog-header">
        <form [formGroup]="createOutletForm" (submit)="createOutlet()">
            <div class="flex flex-col mt-5 space-y-2 w-full">
                <div class="form_div">
                    <label for="">Store</label>
                    <div class="rounded-md border">
                        <p-dropdown [options]="stores" formControlName="storeId" placeholder="Select a Store"
                            optionLabel="name" inputId="storeId-label"></p-dropdown>
                    </div>
                </div>
                <div class="flex gap-4">
                    <div class="form_div">
                        <label for="">Outlet's Name</label>
                        <input class="p-3 rounded-md border" placeholder="Outlet Name" formControlName="name" type="text"
                            required />
                    </div>
                    <div class="form_div">
                        <label for="">Select Outlet's Currency</label>
                        <p-dropdown class="rounded-md border" [options]="currencies" formControlName="currency"
                            placeholder="Select a Currency" optionLabel="name" inputId="float-label"></p-dropdown>
                    </div>
                </div>
                
                <div class="form_div">
                    <label for="">Image</label>
                    <p-fileUpload [auto]="true" mode="basic" [chooseLabel]="selectedFile?.name || 'Choose product image'"
                        chooseIcon="pi pi-upload" name="demo[]"
                        [style]="{'border': '1px solid #ccc', 'border-radius': '5px', 'padding': '10px', 'width': '100%', 'background-color':'#580c8b'}"
                        url="https://www.primefaces.org/cdn/api/upload.php" accept="image/*" maxFileSize="100000"
                        (onUpload)="onUpload($event)" />
                </div>
                <div class="flex gap-4">
                    <div class="form_div">
                        <label for="">Opening Time</label>
                        <input class="p-3 rounded-md border" placeholder="Opening Time" formControlName="opening" type="time" required />
                    </div>
                    <div class="form_div">
                        <label for="">Closing Time</label>
                        <input class="p-3 rounded-md border" placeholder="Closing Time" formControlName="closing" type="time" required />
                    </div>
                </div>
                <div class="flex justify-end items-end w-full">
                    <button class="px-6 py-2 text-white rounded-sm bg-vendblocpurple" type="submit">Create</button>
                </div>
            </div>
        </form>
    </p-dialog>
</app-admin-layout>