<footer class="relative flex flex-col items-center bg-vendblocpurple md:flex-row justify-between px-2 sm:px-5 md:px-12 py-[12px] w-full bottom-0 m-0">
  <div>
      <img src="../../../../assets/logo_white.png" class="h-10" alt="logo">
  </div>
  <!-- VendBloc -->
  <div class="flex items-center text-sm text-white opacity-80 md:text-base">
    &copy; 2024 VendBloc Inc. All Rights Reserved.
  </div>
  <!-- auth buttons and cart -->
  <div class="flex items-center space-x-5">
    
  </div>
</footer>
