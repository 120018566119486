<app-auth-layout>
    <div class="flex flex-col items-center w-full">
    <form [formGroup]="resetPasswordForm" (submit)="resetPassword()">
        <!-- <div class="flex flex-col items-center md:w-[100%]"> -->
            <div class="flex flex-col items-center md:w-full">
                <h1 class="mb-4 text-3xl font-bold">Reset your password</h1>
                <span class="text-center text-gray-500">Enter your new credentials </span>
            </div>
           
            <div class="form_div">
                <label for="">Enter Your Email Address</label>
                <input [ngModel]="resetPasswordForm.value.email" required class="p-3 rounded-md border" placeholder="Input Your Email" formControlName="email" type="email" required>
            </div>
            <app-password-field ngDefaultControl inputId="password" [formControl]="resetPasswordForm.controls.password"></app-password-field>
            
            <div class="form_div">
                <button class="create_button flex justify-center space-x-4 bg-[#0F172A] text-white hover:bg-[#FAFAFA] hover:text-[#0F172A] hover:shadow-lg duration-300" type="submit">
                    <!-- <div class="w-8 h-8 rounded-full border-t-4 animate-spin"></div> -->
                    <span>Reset</span></button>
            </div>
            
            <div class="flex flex-row justify-center mt-6 space-x-1 w-full lg:justify-start">
               <span class="text-gray-500 font-[400]"> Don't have an account ? </span>
               <a routerLink="/register" routerLinkActive="active" class="hover:underline font-[500] hover:cursor-pointer">Create a free account</a>
            </div>
            <div class="flex flex-row justify-center mt-6 space-x-1 w-full lg:justify-start">
                <span class="text-gray-500 font-[400]"> Request Email Verification ? </span>
                <a routerLink="/request-verification" routerLinkActive="active" class="hover:underline font-[500] hover:cursor-pointer">Request</a>
             </div>
        <!-- </div> -->
    </form>
    </div>
</app-auth-layout>