<div class="form_div">
    @if(label !== ""){
      <label [for]="inputId">{{label}}</label>
    }

    <textarea 
      class="p-2 rounded-md border"
      rows="5"
      cols="30" 
      [placeholder]="placeHolder"
      [formControl]="formControl"
      [maxlength]="maxChars">
    </textarea>

    @if(showCharCount){
      <div class="mt-1 text-xs text-right text-gray-500">
        {{currentLength}}/{{maxChars}} characters
      </div>
    }

    <div class="error-container">  
      @if(formControl!.invalid && (formControl!.dirty || formControl!.touched || formSubmitted)){
        <div class="flex flex-col items-start text-xs italic text-red-400">
          @if(formControl!.errors?.['required']){
            <span>Required</span> 
          }
          @if(formControl!.errors?.['email']){
            <span>Please enter a valid email</span>
          }
          @if(formControl!.errors?.['minlength']){
            <span>Enter at least {{formControl!.errors?.['minlength'].requiredLength}} characters</span>
          }
          @if(formControl!.errors?.['maxlength']){
            <span>Maximum {{formControl!.errors?.['maxlength'].requiredLength}} characters allowed</span>
          }
          @if(formControl!.errors?.['pattern']){
            <span>Invalid format</span>
          }
          @if(formControl!.errors && !formControl!.errors['required'] && !formControl!.errors['email'] && !formControl!.errors['minlength'] && !formControl!.errors['maxlength'] && !formControl!.errors['pattern']){
            <span>Invalid input</span>
          }
        </div>
      } 
    </div>
  </div>
  