import { Component, Input, Output, EventEmitter  } from '@angular/core';
import { HeaderComponent } from "../header/header.component";

@Component({
  selector: 'app-user-layout',
  standalone: true,
  imports: [HeaderComponent],
  templateUrl: './user-layout.component.html',
  styleUrl: './user-layout.component.css'
})
export class UserLayoutComponent {

  @Input() showCart = false;
  @Input() cartCount = '';
  @Output() cartEventEmitter = new EventEmitter<void>();

  cartEvent(){
     this.cartEventEmitter.emit();
  }
}
